/*
*Header...............main layout, color,  navbar
*
*Header - Navbar..... main layout typography and color, typography,
*                              and hover effect
*Desktop version......sandvich menu hidden, underline shown on hover
*/

/*Sticky header*/

header {
  width:            100%;
  display:          flex;
  flex-flow:        row nowrap;
  justify-content:  space-between;  
  align-items:      center;
  align-self:       flex-start;
  background:       var(--brand-color) 0% 0% no-repeat padding-box;
  opacity:          1;
  padding-left:     10%;
  padding-right:    10%;
  position:         fixed;
  z-index:          100; 
 
  
}

  
  /* Navbar */
 
  .js-nav--wrapper {
  display: flex;
  width:   100%;
  position: fixed;
  top:     var(--header-height);
  left:    0;
}

  .js-nav--wrapper > * {
    width: 100%;
  }
    .nav {
      max-height:       0;
      display:          flex;
      width:            100%;
      gap:              0;
      margin-left:      0; 
      flex-flow:        column nowrap;
      justify-content:  flex-start;
      align-items:      center;
      background-color: var(--brand-color);
      overflow:         hidden;
      transition:       max-height 0.5s ease-out;
    }
    
      .js-nav--wrapper.js-is-open .nav {
        max-height: 100%;
      }
    
        .nav > * { 
          border-bottom:   1px solid rgba(var(--secondary-color-rgb), 0.65); /*[1]*/
          padding-top:     1px;
          width:           100%;
          height:          2rem;
          z-index:         100;
          display:         flex;
          justify-content: center;
          align-items:     center;
         
        }

        .js-nav--wrapper.js-is-open .nav .nav--item__last { /*[1]*/
          border-bottom: none;
        }
    
        /* Ez kell? */    
       /* .js-nav--wrapper.js-is-open .nav > .js-nav--item:hover::after {  [2] 
          display: none;
        }*/
         
      
    .js-nav--menu {
      display:          block;
      color:        var(--secondary-color);
    }
    
    
    
      .js-nav--menu.js-highlight {
        opacity:          1;
      }

    /*Active link is highlighted*/
    
    .js-nav--item__active {
      font-weight:     bold;
      
    }



    /*--------------------------------*/
    /*DESKTOP LAYOUT*/
    /*--------------------------------*/
    

    @media (min-width: 1200px) {
      header {
        max-width:     1440px;
        padding-left:  5%;
        padding-right: 5%;
        
      }
      .js-nav--wrapper {
        display: flex;
        width:   80%;
        position: unset;
      }
  
      .js-nav--wrapper > * {
        width: 100%;
      }  
    .nav {
      width:           100%;
      display:         flex;
      max-height:      100%;
      flex-flow:       row nowrap;
      justify-content: space-between;
      align-items:     center;
      gap:             .5rem;
    }

      .nav > * {
        border-bottom: none;
      }
    
      .js-nav--item, .js-nav--item__logo {
        position:        relative; /* to position the underline */
        display:         flex;
        justify-content: center;
        align-items:     center;
        padding:         0;
        height:          var(--header-height);
      }
    
      /*in case of hover, underline is shown at the bottom of the navbar*/
    
        .js-nav--item:hover::after {
          content:          "";
          display:          block;
          width:            100%;
          height:           0.2rem;
          background-color: var(--secondary-color);
          position:         absolute;
          bottom:           0;
          left:             0;
         
        }
      
    
        .js-nav--item:hover, .js-nav--menu:hover {
          
          transition:       0.3s;
        }
  
        .js-nav--item__logo:hover::after {
          content:          "";
          display:          block;
          width:            100%;
          height:           0.2rem;
          background-color: var(--secondary-color);
          position:         absolute;
          bottom:           0;
          left:             0;
          
        
        }
  
      /*Active link is highlighted*/
    
        .js-nav--item__active {
          font-weight:     bold;
        }
    
  
    /*Sandvich menu is not shown on desktop */
    
    
    .js-nav--menu {
      display:         none;
      color:           var(--secondary-color);
      
         
    }
  }
    


    