.btn {
      
    display:        inline-block;
    width:          clamp(11.9248rem, 90%, 15rem);
    border:         0.125rem solid;
    opacity:        1;
    padding:        .5rem;
    font-size:      var(--font-size-small);
    line-height:    var(--line-height-small);
    font-weight:    500;
    text-align:     center;
    text-transform: uppercase;
    align-self:     center;
  }
  
  .btn:hover {
    transition:       0.3s;
  }
  
    .btn__primary {
      background:   var(--brand-color) 0% 0% no-repeat padding-box;
      border-color: var(--brand-color);
      color:        var(--secondary-color);
    }

    .btn__primary:hover {
      background:   transparent;
      border-color: var(--brand-color);
      color:        var(--brand-color);
    }

    .btn__hollow {
      background:   transparent;
      border-color: var(--brand-color);
      color:        var(--brand-color);
    }

    .btn__hollow:hover {
      background:   var(--brand-color);
      border-color: var(--brand-color);
      color:        var(--secondary-color);
    }

    .btn__hero {
      background:   var(--brand-color) 0% 0% no-repeat padding-box;
      border-color: var(--brand-color);
      color:        var(--secondary-color);
    }

    .btn__hero:hover {
      background:   transparent;
      border-color: var(--secondary-color);
      color:        var(--secondary-color);
    }

    .btn__hero__hollow {
      background:   transparent;
      border-color: var(--brand-color);
      color:        var(--secondary-color);
    }

    .btn__hero__hollow:hover {
      background:   var(--brand-color) 0% 0% no-repeat padding-box;
      border-color: var(--brand-color);
      
    }

    .buttons--wrapper {
      display:         flex;
      flex-flow:       column nowrap;
      justify-content: space-between;
      width:           100%;
      gap:             1.4rem;
    
    }

 
@media (min-width: 568px) {
  .buttons--wrapper {
    flex-flow:       row nowrap;
    justify-content: center;
    gap:             1rem;
  }
}
@media (min-width: 768px) {
  .buttons__hero--wrapper {
    justify-content: flex-start;
    gap:             1.4rem;
    width:           60%;
  }
}

@media (min-width: 1920px) {
  .btn {
    font-size: 1rem;
  }
}




    /* On tablet and mobile there is no hover effect */

    /*@media (max-width: 923px) {
      .btn__primary:hover {
        background:   var(--brand-color) 0% 0% no-repeat padding-box;
        border-color: var(--brand-color);
        color:        var(--secondary-color);
      } 
      .btn__hollow:hover {
        background:   transparent;
        border-color: var(--secondary-color);
        color:        var(--secondary-color); 
      }
    }
  */
    
