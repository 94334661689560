/* Contact icons */

.icons--wrapper {
    height:          2rem;
    display:         flex;
    justify-content: flex-start;
    gap:             .625rem;

  }

    .icons {
      display:          flex;
      flex-flow:        row nowrap;
      justify-content:  center;
      align-items:      center;
      width:            2rem;
      height:           2rem;
      background-color: var(--brand-color);
      color:            var(--primary-color);
      opacity:          .8;
      border-radius:    50%;
    }

    .icons:hover {
      background-color: transparent;
      color:            var(--brand-color);
      border:           .125rem solid var(--brand-color);
    }  


    .icons__light {
      background-color: var(--tertiary-color);
      color:            var(--primary-color);
    }

    .icons__light:hover {
        background-color: transparent;
        color:            var(--tertiary-color);
        border:           .125rem solid var(--tertiary-color);
      }  
