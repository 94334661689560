

/*--------------------------------*/
/* MOBILE LAYOUT FOR THE MAIN PAGE */
/*--------------------------------*/


.article {
  display:         flex;
  flex-flow:       column nowrap;
  justify-content: flex-start;
  align-items:     center;
  width:           min(55.1015625rem, 100%);
  width:           min(58rem, 100%);
} 

.article__last {
  margin-bottom: 5vw;
}

.article--title {
  margin-top:    1rem;
  margin-bottom: 1rem;
}

.article--img {
  width:         min(26.879875rem, 100%);
  object-fit:    cover;
  margin-bottom: 1rem;
}

.article--content {
  display:         flex;
  flex-flow:       column nowrap;
  justify-content: flex-start;
  align-items:     center;
  width:           min(26.879875rem, 100%);
  gap:             1.5rem;
  
}

.align-left {
  align-self: flex-start;
}

  

  .article--list li {
    list-style-position: inside;
    display:             flex;
    align-items:         flex-start;
    
  }

  .article--list {
    padding-left: 0;
  }
  
  
  
  .article--list li:before {
    content:      "•"; 
    margin-right: 0.5rem; /* A bullet point távolsága a szövegtől */
    font-size:    1.5rem;
  }


 



  /*--------------------------------*/
  /*MOBILE LAYOUT FOR THE SUBPAGES*/
  /*--------------------------------*/

  .img-background {
    width:           100%;
    max-height:      50vh;
    display:         flex;
    justify-content: center;
    align-items:     center;
    margin-bottom:   0;
    
  }
  .img__fullwidth {
    width:           100%;
    max-height:      50vh;
    object-fit:      cover;
    object-position: top;
    margin-bottom:   0;
  }
    .crop-top {
      object-position: bottom;
    }
    .crop-center {
      object-position: 50% 85%;
    }
    
  .article--wrapper {
    gap:              1rem;
    width:            100%;
    background-color: var(--secondary-color);
    
  }

    .article__detailed {
      display:         flex;
      flex-flow:       column nowrap;
      justify-content: flex-start;
      align-items:     center;
      width:           min(45rem,100%);
      gap:             1rem;
      margin-top:      1rem;
      margin-bottom:   2rem;
    }
 
     
    .article__detailed--content {
      display:         flex;
      flex-flow:       column nowrap;
      justify-content: flex-start;
      align-items:     center;
      width:           100%;
      gap:             1rem;
      margin-bottom:   2rem;
     
    }

   #mikor-fordulj-hozzam { 
      scroll-margin-top: calc(var(--header-height) + 1rem); 
    }

  



/*--------------------------------*/
/* TABLET AND DESKTOP FOR MAIN PAGE */
/*--------------------------------*/

@media (min-width: 400px) {
  .article--title {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 992px) {
  .article {
    display:               grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:    auto;
    grid-template-areas:
      "img title"
      "img content";
    column-gap:            1.5rem;
    row-gap:               0;
    margin-top:            5vw;
  }
  .article--title {
    grid-area:  title; 
    align-self: flex-end;
    margin-top: 0; 
    text-align: left;
  }
  .article--img {
    grid-area:     img; 
    align-self:    stretch;
    justify-self:  center; 
    margin-bottom: 0;
  }
  .article--content {
    grid-area:    content;
    align-self:   start;
    justify-self: center; 
  }
  .article__middle {
    grid-template-areas:
    "title img"
    "content img";
  }
  .article--content > .btn {
    align-self: flex-start;
  }
}

@media (min-width: 1200px) {
  .article {
    column-gap:    4rem;
  }
}




/*--------------------------------*/
/* TABLET AND DESKTOP FOR SUBPAGES */
/*--------------------------------*/
@media (min-width: 400px) {
  .article__detailed {
    margin-top: 2rem;
  }
}
  
@media (min-width: 768px) {
 .article__detailed--title {
  margin-bottom: 1rem;
  }
  .article__detailed--content {
    gap:           1.5rem;
    margin-bottom: 3rem;
  }
}





