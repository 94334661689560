/*-----------------------------------*\
* BASIC SETTINGS OF THE PAGE
\*-----------------------------------*/

:root {
    --primary-color:        #011217; /* dark blue -almost black */
    --primary-color-rgb:    1, 18, 23;
    --secondary-color:      #FFFFFF; /* white */
    --secondary-color-rgb:  255, 255, 255;
    --brand-color:          #5789a2; 
    --brand-color-rgb:      87, 137, 162;
    --background-color:     #F4F3F5; /* light grey*/
    --tertiary-color:       #FEEECA; /* light yellow */
    --tertiary-color-rgb:   254, 238, 202;
    --font-size-regular:    clamp(1rem, .9vw + .8rem, 1.25rem);
    --line-height-regular:  clamp(1.5rem, 1.9vw + 1rem, 1.875rem);
    --hero-title:           clamp(1.25rem, 2.5641vw + 0.673rem, 3.75rem);
    --font-size-big:        calc(var(--font-size-regular) * 1.8);
    --line-height-big:      calc(var(--line-height-regular) * 1.66);
    --font-size-small:      0.875rem;
    --line-height-small:    1.1rem;
    --header-height:        clamp(3.5rem, 5vw + 2rem, 6rem);
    --hero-height:          calc(100dvh - var(--header-height));  
    --footer-height:        7.5rem; 
} 

/* normalize CSS */

* {
    margin:     0;
    padding:    0;
    box-sizing: border-box;
}

  
  html, body {
    width:      100%;
    overflow-x: hidden;
  }


   body {
    background-color: var(--secondary-color);
   }

/*** TYPOGRAPHY ***/

html {
  font-size:        16px;
  color:            var(--primary-color);
}

  
  body {
    width:       100vw;
    font-family: "Noto Sans", sans-serif;
    font-weight: 400;
    text-align:  left;
    font-size:   var(--font-size-regular);
    line-height: var(--line-height-regular);
  }


    a {
      text-decoration: none;
    }

/*Title*/

    .title {
      font-family:   'Montserrat', sans-serif;
      font-weight:    bold;
      font-size:      calc(var(--font-size-regular) * 1.5);
      line-height:    calc(var(--line-height-regular) * 1.5);
      margin:         0;
      margin-bottom:  .5rem;
    }
     
      
      .title__hero {
        font-size:      var(--hero-title);
        line-height:    calc(var(--hero-title) * 1.5);
        font-weight:    600;
        text-align:     center;
      }

      


    .title__center {
      text-align: center;
    }

    

    
/*Text*/


    .text__hero {
      text-align:  center;
    }

    
    .text__small {
      font-size:   var(--font-size-small);
      line-height: var(--line-height-small);
    }

    .text__logo {
      font-family: Arizonia, cursive;
      font-size:   clamp(1.5rem, 1.5vw + 1rem, 2rem);
      
      line-height: var(--header-height);
      color:       var(--secondary-color);
    }

    .text__nav {
      font-family:    'Montserrat', sans-serif;
      font-size:      var(--font-size-small);
      line-height:    var(--line-height-small);
      color:          var(--secondary-color);
      font-weight:    500;
    }

    .text__brand {
      color: var(--brand-color);
    }
     

    .text__bold {
      font-weight: 600;
    }

    .text__50 {
      width: min(420px, 90%);
      margin:  0 auto;
    }

      @media (min-width: 601px) {
        .text__50 {
          width:   50%;
        }
      }   

@media (min-width: 1440px) {
  .text__nav {
    font-size:     1rem;
  }
  .text__hero {
    font-size:     1.5rem;
  }
} 
   

  

