.contacts--wrapper {
  gap:             2.5rem;
  padding:         3rem 2rem 3rem 2rem;
 
  
}

  .article__contacts {
    display:         flex;
    flex-flow:       column nowrap;
    justify-content: flex-start;
    align-items:     center;
    gap:             1rem;
    width:           min(18.416rem,100%);
  }

    .article__contacts--title {
      margin-bottom: 0;
    }

    .contact--link {
      color:           var(--primary-color);
      text-decoration: none;
      display:         block;
      line-height:     1.5rem;
    }

    

      .contact--icon {
        padding-right: .5rem;
        color:         var(--primary-color);
     
      }

    .icon-link--wrapper {
      display: flex;
      justify-content: flex-start; 
      align-items: baseline;
      gap:          .5rem; 
    }

      .contact--map {
        width:        100%;
        aspect-ratio: 1/1;
        border:       none;
      }


      .free-consult, .prices {
        text-align: center;
      }

      .prices {
        margin-bottom: 1.5rem;
      }

/*********************************/
/*TABLET AND DESKTOP LAYOUT */
/*********************************/

@media (min-width: 768px) {
  .contacts--wrapper {
    flex-flow:       row nowrap;
    justify-content: center;
    align-items:     flex-start;
    padding-left:    10%;
    padding-right:   10%;
    gap:             10%;   
  }
  .article__contacts {
    box-sizing: content-box;
    height: 50%;
    
    width:  min(20rem,100%);
   
  }
  
  .free-consult, .prices {
    text-align: left;
  }
.prices {
  margin-bottom: 2rem;
}
.free-consult {
 padding-left: 1.9rem;
  
}
}
@media (min-width: 1024px) {
  .contact--link:hover {
    text-decoration: underline;
  }
}

@media (min-width: 1500px) {
  .contacts--wrapper {
    gap: 5%;
  }
  .article__contacts {
    padding: 2rem 3rem 2rem 3rem;
    background-color: var(--secondary-color);
  }
}

@media (min-width: 1500px) and (min-height: 960px) {
  .article__contacts {
    height:  50vh;
    margin: auto 0 auto 0;
  }
}