/*--------------------------------*\
* LAYOUT 

*Container............overall layout of the page
*
*Main.................over layout of the content
*
\*--------------------------------*/


/*--------------------------------*\
* OVERALL LAYOUT OF THE PAGE
\*--------------------------------*/

.container {
    display:        flex;
    flex-flow:      column nowrap;
    align-items:    center;
    min-height:     100vh;
    max-width:      1440px;
    margin:         0 auto;
    position:       relative;
}
  
  
  
  
  
  /*--------------------------------*\
  * CONTENT LAYOUT
  \*--------------------------------*/

  main {
    display:         flex;
    flex-flow:       column nowrap;      
    justify-content: flex-start;
    align-items:     center;
    flex-grow:       1;
    width:           100%;
    margin-top:      var(--header-height);
    
  }

  .section {
    display:         flex;
    flex-flow:       column nowrap;
    justify-content: flex-start;
    align-items:     center;
    gap:             2rem;
    padding:         1rem;
  }

  @media (min-width: 400px) {
    .section {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  @media (min-width: 768px) {
    .section {
      padding-left:  10%;
      padding-right: 10%;
      gap:           3rem;
      padding-top:   3rem;
    }
  }

  @media (min-width: 1440px) {
  
    body {
     background-color: rgba(var(--brand-color-rgb), 0.1);
    }
    .section {
      background-color: var(--secondary-color);
    }
    
  }
  

 